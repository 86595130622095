import React from 'react';
import styled, { css } from 'styled-components';

const Column = styled.div`
	width: 100%;
	text-align: left;
	&:first-of-type {
		margin-bottom: 50px;
	}
	${p =>
		p.theme.media.mediumDown(css`
			text-align: center;
		`)}
	${p =>
		p.theme.media.large(css`
			width: 50%;
			&:first-of-type {
				margin-bottom: 0;
			}
		`)}
	p {
		margin: 0;
		font-size: 20px;
		line-height: 26px;
		${p =>
			p.theme.media.medium(css`
				font-size: 22px;
				line-height: 30px;
			`)}
	}
`;

const Row = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: ${p => (p.centered ? 'center' : 'flex-start')};

	${p =>
		(p.centered &&
			css`
				text-align: center;
				${Column} {
					${p =>
						p.theme.media.large(css`
							text-align: center;
							&:first-of-type {
								padding-right: 0;
							}
							&:last-of-type {
								padding-left: 0;
							}
						`)}
				}
			`) ||
		css`
			text-align: left;
			${Column} {
				${p =>
					p.theme.media.large(css`
						text-align: left;
						&:first-of-type {
							padding-right: 100px;
						}
						&:last-of-type {
							padding-left: 100px;
						}
					`)}
			}
		`}
`;

const Scores = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

const Score = styled.div`
	width: 50%;
	text-align: center;
	&:first-of-type {
		padding-right: 30px;
	}
	&:last-of-type {
		padding-left: 30px;
	}
`;

const Star = styled.span`
	color: ${p => (p.$filled ? p.theme.colors.yellow : p.theme.colors.grey400)};
	font-size: 20px;
	margin: 0 1px;
	${p =>
		p.theme.media.medium(css`
			font-size: 24px;
			margin: 0 3px;
		`)}
`;

const ServiceName = styled.p`
	margin: 0;
	font-size: 20px;
	line-height: 30px;
	margin-top: 5px;
`;

const Rate = styled.p`
	margin: 0;
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
`;

/**
 * Component for rendering the app-ratings section.
 * @param {Object} props - The props for the component.
 * @returns {JSX.Element} The ratings element.
 */
export default function Ratings({ ...props }) {
	return (
		<Row className="app-ratings" {...props}>
			<Column>
				<p>“Enkelt og oversiktlig! 😍” – Rigmor, NTE-kunde</p>
			</Column>
			<Column>
				<Scores>
					<Score>
						<Stars service="Google Play" score="4" total="5" />
					</Score>

					<Score>
						<Stars service="App Store" score="4.3" total="5" />
					</Score>
				</Scores>
			</Column>
		</Row>
	);
}

/**
 * Component for rendering the stars.
 * @param {Object} props - The props for the component.
 * @param {string} props.service - The service name.
 * @param {string} props.score - The score.
 * @param {string} props.total - The total score.
 * @returns {JSX.Element} The stars element.
 */
const Stars = ({ service, score, total }) => {
	if (!service || !score || !total) return null;
	return (
		<div
			aria-label={`NTE Appen har ${score} av ${total} stjerner som score på ${service}`}>
			{[...Array(parseInt(total))].map((_, index) => {
				return (
					<Star key={index} $filled={parseInt(score) >= index + 1}>
						&#9733;
					</Star>
				);
			})}

			{service && <ServiceName>{service}</ServiceName>}
			{score && total && <Rate>{`${score} av ${total}`}</Rate>}
		</div>
	);
};
