import React from 'react';
import styled, { css } from 'styled-components';

import useWindow from 'context/useWindow';
import { showContent } from 'libs/animations';
import { AnchorLink } from 'components/Link';
import ScreenReaderText from '../screen-reader-text';
import GooglePlayBadge from 'images/app/badge-google-play.inline.svg';
import AppStoreBadge from 'images/app/badge-app-store.inline.svg';

const Buttons = styled.div`
	text-align: ${p => (p.centered && 'center') || 'left'};
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	z-index: 5;
	margin-top: ${p =>
		(p.$belowText === 'false' &&
			p.theme.spacing.desktop.xxsmall + '!important') ||
		p.theme.spacing.desktop.xsmall};
	${p =>
		p.theme.media.mediumDown(css`
			margin-top: ${p =>
				(p.$belowText === 'false' &&
					p.theme.spacing.tablet.xxsmall + '!important') ||
				p.theme.spacing.tablet.small};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			margin-top: ${p =>
				(p.$belowText === 'false' &&
					p.theme.spacing.mobile.xxsmall + '!important') ||
				p.theme.spacing.mobile.small};
		`)}
	${p =>
		p.theme.media.smallOnly(css`
			padding: 0;
		`)}
	${p =>
		p.$animate &&
		css`
			animation-name: ${showContent};
			animation-delay: 1.25s;
			animation-duration: 2s;
			animation-fill-mode: both;
		`}
	${p =>
		p.theme.media.smallOnly(css`
			margin: 0 0 0 0;
		`)}
	${p =>
		p.hidetitle === 'false' &&
		css`
			max-width: ${p => p.theme.widths.medium};
			${p => (p.centered && 'margin: 0 auto;') || ''}
		`}
`;

const Button = styled(AnchorLink)`
	display: inline-flex;
	margin: ${p =>
		`${p.theme.spacing.desktop.xxsmall} ${p.theme.spacing.desktop.xsmall} 0
			0`};

	${p =>
		p.theme.media.smallOnly(css`
			&:first-of-type {
				margin-right: 5px;
			}
			&:last-of-type {
				margin-left: 5px;
			}
		`)}

	svg {
		transition: opacity 350ms ease;
		display: block;
		&:hover {
			opacity: 0.65;
		}
	}
`;

/**
 * DownloadApp component
 * @param {Object} props - The props object.
 * @param {boolean} props.animate - If the component should animate.
 * @param {string} props.belowtext - If the component should be placed below text.
 * @returns {Node} - The DownloadApp component.
 **/
export default function DownloadApp({
	animate = false,
	belowtext = 'false',
	...props
}) {
	const { app } = useWindow(); // Get the app links from the window context.

	if (!app?.iOS && !app?.android) return null; // If no app links are provided, return null.

	return (
		<Buttons
			$animate={animate}
			$belowtext={belowtext}
			className="app-download-buttons"
			data-cy="download-app-buttons"
			{...props}>
			<Button
				role="button"
				title="Last ned NTE Appen fra App Store"
				target="_blank"
				rel="noopener noreferrer"
				className="app-download-button__apple"
				href={app?.iOS}>
				<AppStoreBadge height="46" width="138" />
				<ScreenReaderText>
					Last ned NTE Appen fra App Store
				</ScreenReaderText>
			</Button>
			<Button
				role="button"
				title="Last ned NTE Appen fra Google Play"
				target="_blank"
				rel="noopener noreferrer"
				className="app-download-button__google"
				href={app?.android}>
				<GooglePlayBadge height="46" width="150" />
				<ScreenReaderText>
					Last ned NTE Appen fra Google Play
				</ScreenReaderText>
			</Button>
		</Buttons>
	);
}
